input.authenticate-form {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 8px #00000026;
    border-radius: 10px;
    padding: 15px 30px;
    width: 100%;
    color: #000000;
    font-weight: 600;
    border: 0px;
}

.social-signin {
    font-size: 12px;
}

.social-signin .social-facebook {
    color: #3b5998;
}

.social-signin .social-google {
    color: #DB4437;
}

a.social-login {
    box-shadow: 0px 1px 8px #00000026;
    border: none;
    border-radius: 8px;
    display: inline-block;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 550;
    letter-spacing: 0;
    line-height: 2;
    margin-bottom: 0;
    padding: 10px 25px;
    transition: all 0.3s ease 0s;
}

a.social-login.facebook {
    background: #1696e7;
}

.social-login.google {
    background: #DB4437;
}

a.social-login:hover {
    color: #FFFFFF;
}

a.forgotpassword {
    color: #ffae25;
    text-decoration: underline;
}
